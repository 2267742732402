import React, { Fragment } from "react"

const SummaryTable = ({ results, dates }) => {
  const createRow = (id, type, value, classes, index) => {
    return (
      <tr key={`${type}-row-${index}`} className={classes}>
        <td className="py-2 px-4 border text-center">{id}</td>
        <td className="py-2 px-4 border text-center">{type}</td>
        {Object.keys(value).map((key, index) => {
          if (key !== "id") {
            return (
              <td
                key={`${type}-row-date-${index}`}
                className={`${
                  value[key][type] === 0 ? "text-gray-400" : ""
                } px-4 py-2 border text-center`}
              >
                {value[key][type]}
              </td>
            )
          }
        })}
      </tr>
    )
  }

  return (
    <div className="flex mt-10">
      <div className="text-center h-full">
        <table className="table-fixed text-gray-700 text-xs font-bold border-r-2 border-gray-300">
          <thead>
            <tr>
              <th className="py-2 border">Name</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(results).length > 0 &&
              Object.keys(results).map((username, index) => (
                <Fragment key={`rows-usernames-${index}`}>
                  <tr key={`${username}-row-1`}>
                    <td className="py-2 px-4 border">{username}</td>
                  </tr>
                  <tr key={`${username}-row-2`}>
                    <td className="py-2 px-4 border">{username}</td>
                  </tr>
                  <tr key={`${username}-row-3`}>
                    <td className="py-2 px-4 border">{username}</td>
                  </tr>
                  <tr key={`${username}-row-4`}>
                    <td className="py-2 px-4 border">{username}</td>
                  </tr>
                </Fragment>
              ))}
          </tbody>
        </table>
      </div>
      <div className="flex-1 flex overflow-auto">
        <table className="flex-1 table-fixed text-gray-700 text-xs overflow-x-scroll font-bold">
          <thead className="">
            <tr className="py-2">
              <th className="w-16 py-2 border">ID</th>
              <th className="w-24 py-2 border">Type</th>
              {dates.map(date => (
                <th key={date} className="border">
                  {date.replace("-2020", "")}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(results).length > 0 &&
              Object.keys(results).map((username, index) => {
                let value = results[username]

                return (
                  <Fragment key={`rows-${index}`}>
                    {createRow(value.id, "POS", value, "text-red-500", index)}
                    {createRow(value.id, "PP", value, "text-orange-500", index)}
                    {createRow(value.id, "NEG", value, "text-green-500", index)}
                    {createRow(value.id, "ALL", value, "text-blue-500", index)}
                  </Fragment>
                )
              })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SummaryTable
