import React, { useEffect, useState } from "react"
import * as cQueries from "../../graphql/customQueries"
import * as queries from "../../graphql/queries"
import { userPoolsClient } from "../../../gatsby-browser"
import { FormGroup, HTMLSelect, Spinner, Checkbox } from "@blueprintjs/core"
import { formatDate } from "../../utils/core"
import { getResultStatus, ResultEnum } from "../../utils/results"
import { exportSingleDateToCSV, exportToCSV } from "../../utils/csv"
import gql from "graphql-tag"
import SummaryTable from "./summary/SummaryTable"
import SummaryChart from "./summary/SummaryChart"
import SummaryIdHistogram from "./summary/SummaryIdHistogram"

const LOAD_SIZE = 100000

const getDates = plates => {
  let dates = new Set()

  plates.forEach(plate => {
    dates.add(plate.date)
  })

  let sorted = Array.from(dates).sort((d1, d2) => {
    let date1 = new Date(d1)
    let date2 = new Date(d2)

    return date1 < date2 ? 1 : date1 > date2 ? -1 : 0
  })

  let formatted = []
  for (let i = 0; i < sorted.length; i++) {
    formatted[i] = formatDate(new Date(sorted[i]))
  }

  return formatted
}

const excluded = ["tester", "TestSurgery", "admin"]

const combineData = async (plates, dates) => {
  if (plates.length === 0) return {}

  const data = {
    Total: {
      id: "TOT",
    },
  }

  dates.forEach(date => {
    data["Total"][date] = {
      POS: 0,
      NEG: 0,
      PP: 0,
      ALL: 0,
    }
  })

  const uniqueIds = {}
  let platesNotFound = 0

  //construct
  for (let plate of plates) {
    const results = plate.results.items
    for (let i in results) {
      const result = results[i]
      const { user, donorId } = result

      if (!excluded.includes(user)) {
        if (!Object.keys(data).includes(user)) {
          //first entry for user.
          const id = donorId.split("_")[0]
          data[user] = {
            id,
          }
          dates.forEach(date => {
            data[user][date] = {
              POS: 0,
              PP: 0,
              NEG: 0,
              ALL: 0,
            }
          })
        }

        let numRecs = 1
        let pool = false
        if (donorId.includes("_P")) {
          //pooledData - let's find out how many.
          pool = true
          const key = `${formatDate(
            new Date(plate.date)
          ).trim()} - ${donorId.trim()}`
          const poolList = await userPoolsClient.query({
            query: gql(queries.getPoolList),
            variables: {
              id: key,
            },
          })
          if (poolList.data.getPoolList) {
            poolList.data.getPoolList.resultIds.forEach(id => {
              uniqueIds[id] = uniqueIds[id] + 1 || 1
            })
            numRecs = poolList.data.getPoolList.resultIds.length
          } else {
            platesNotFound++
          }
        } else {
          uniqueIds[donorId] = uniqueIds[donorId] + 1 || 1
        }

        const d = formatDate(new Date(plate.date))
        const status = getResultStatus(result)

        switch (status) {
          case ResultEnum.POSITIVE: {
            if (!pool) {
              data[user][d].POS += numRecs
              data["Total"][d].POS += numRecs
            }
            break
          }
          case ResultEnum.PARTIAL_POSITIVE: {
            if (!pool) {
              data[user][d].PP += numRecs
              data["Total"][d].PP += numRecs
            }
            break
          }
          default: {
            data[user][d].NEG += numRecs
            data["Total"][d].NEG += numRecs
          }
        }

        if (!pool || (pool && status === ResultEnum.NEGATIVE)) {
          data[user][d].ALL += numRecs
          data["Total"][d].ALL += numRecs
        }
      }
    }
  }

  const allData = {
    results: data,
    testsById: uniqueIds,
  }

  return allData
}

const SummaryPanel = ({ ...rest }) => {
  const [plates, setPlates] = useState([])
  const [date, setDate] = useState("All")
  const [data, setData] = useState({
    testsById: {},
    results: {},
  })
  const [userState, setUserState] = useState(null)
  const [userMap, setUserMap] = useState(null)

  const fetchUsers = async () => {
    try {
      const returnedUsers = await userPoolsClient.query({
        query: gql(cQueries.listUsers),
        variables: {
          limit: 1000,
        },
        fetchPolicy: "network-only",
      })
      let map = {}
      returnedUsers.data.listUsers.items.forEach(u => {
        map[u.prefix] = u
      })
      setUserMap(map)
    } catch (err) {
      // AppToaster.show({
      //     intent: "danger",
      //     message: `${err.message}`,
      //     icon: "error",
      // })
    }
  }

  useEffect(() => {
    if (userMap) {
      let userState = {}
      for (let user of Object.values(userMap)) {
        userState[user.prefix] = true
      }
      setUserState(userState)
    }
  }, [userMap])

  //fetch plates and users
  useEffect(() => {
    const fetchPlates = async () => {
      const props = { limit: LOAD_SIZE }
      const platesReturned = await userPoolsClient.query({
        query: gql(cQueries.listPlatesSummary),
        options: {
          fetchPolicy: "network-only",
        },
        variables: {
          ...props,
        },
      })
      setPlates(platesReturned.data.listPlates.items)
    }
    fetchPlates()
    fetchUsers()
  }, [])

  useEffect(() => {
    const load = async () => {
      const dates = getDates(plates)
      let data = await combineData(plates, dates)
      setData({
        ...data,
      })
    }

    if (plates.length > 0) {
      load()
    }
  }, [plates])

  const dates = getDates(plates)

  const handleExport = date => {
    if (date === "All" || typeof date === "undefined") {
      exportToCSV(
        { ...data, dates },
        `${formatDate(new Date())}-covid19-results-All.csv`
      )
    } else {
      let singleDateData = {}
      Object.keys(data.results).forEach(key => {
        if (data.results[key][date]) {
          const res = data.results[key]
          singleDateData[res.id] = res[date]
        }
      })
      exportSingleDateToCSV(
        singleDateData,
        `SBL-covid19-results-for-${date}.csv`,
        date
      )
    }
  }

  const handleKeyChange = key => {
    const newState = { ...userState }
    newState[key] = !newState[key]
    setUserState(newState)
  }

  const total = {}
  for (let date of dates) {
    total[date] = {}
  }

  let filteredDataTestById = {}
  if (userMap && userState) {
    for (let setting of Object.values(data.results)) {
      if (setting.id !== "TOT") {
        if (userState[setting.id]) {
          for (let date of dates) {
            if (setting[date]) {
              total[date].POS =
                total[date].POS + setting[date].POS || setting[date].POS
              total[date].PP =
                total[date].PP + setting[date].PP || setting[date].PP
              total[date].NEG =
                total[date].NEG + setting[date].NEG || setting[date].NEG
              total[date].ALL =
                total[date].ALL + setting[date].ALL || setting[date].ALL
            }
          }
        }
      }
    }

    for (let key in data.testsById) {
      let prefix = key.split("_")[0]

      if (userState[prefix]) {
        filteredDataTestById[key] = data.testsById[key]
      }
    }
  }

  const boxes =
    userMap &&
    userState &&
    Object.keys(userMap).map(key => {
      return (
        key !== "ALL" && (
          <div className="w-12" key={key}>
            <FormGroup label={key}>
              <Checkbox
                checked={userState[key] || false}
                onChange={() => handleKeyChange(key)}
              />
            </FormGroup>
          </div>
        )
      )
    })

  return (
    <div {...rest}>
      {dates.length === 0 || Object.keys(data.results).length === 0 ? (
        <Spinner intent="primary" />
      ) : (
        <>
          <div className="bg-white p-4 rounded-lg shadow-lg mt-4 text-center capitalize w-full">
            <h3 className="text-gray-900 text-lg font-bold">Settings</h3>
            <div className="flex flex-wrap">{boxes}</div>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-lg mt-4 text-center capitalize w-full">
            <h3 className="text-gray-900 text-lg font-bold">Cases over time</h3>
            <SummaryChart
              results={total}
              dates={[...dates].reverse()}
              classes="h-225"
            />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-lg mt-4 text-center capitalize">
            <h3 className="text-gray-900 text-lg font-bold">
              Unique IDs tested
            </h3>
            <SummaryIdHistogram results={filteredDataTestById} />
          </div>
          <div className="flex justify-between items-center mt-4">
            <FormGroup
              label="Export Data for"
              inline
              className="text-gray-800 font-bold"
            >
              <HTMLSelect
                options={[...dates, "All"].reverse()}
                value={date}
                onChange={e => setDate(e.currentTarget.value)}
              />
            </FormGroup>
            <button
              className="default-btn w-40"
              onClick={() => handleExport(date)}
            >
              Export all settings data to CSV
            </button>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-lg mt-4 text-center capitalize">
            <h3 className="text-gray-900 text-lg font-bold">
              Cases per center
            </h3>
            <SummaryTable results={data.results} dates={[...dates]} />
          </div>
        </>
      )}
    </div>
  )
}

export default SummaryPanel
