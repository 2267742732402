import React, { useState } from "react"
import { parsePoolTableData } from "../../utils/readers/plate_reader"
import { uploadPools } from "../../utils/readers/uploader"
import { FileInput, Spinner } from "@blueprintjs/core"

const PoolTableUploadPanel = ({ handleFileUploadFinished }) => {
  const [selectedFiles, setSelectedFiles] = useState(null)
  const [selectedFileText, setSelectedFileText] = useState(
    "Select one or more files..."
  )
  const [uploading, setUploading] = useState(false)
  const [filesCompleted, setFilesCompleted] = useState(0)
  const [filesToDo, setFilesToDo] = useState(0)

  const handleFileChange = e => {
    const files = e.target.files
    if (files) {
      const name = files.length > 1 ? `${files[0].name} + more` : files[0].name
      setSelectedFileText(name)
      setSelectedFiles(files)
    }
  }

  const uploadPlateFile = async file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onerror = err => {
        err.message = `${file.name}: ${err.message}`
        reject(err)
      }

      reader.onload = async data => {
        try {
          const pools = await parsePoolTableData(data)
          await uploadPools(pools)
          resolve(pools)
        } catch (err) {
          handleFileUploadFinished(err)
          reject(err.message)
        }
      }

      reader.readAsText(file, "utf-8")
    })
  }

  const allProgress = (proms, progress_cb) => {
    let d = 0
    progress_cb(0)
    for (let p of proms) {
      p.then(() => {
        d++
        progress_cb(d)
      })
    }
    return Promise.all(proms)
  }

  const handleButtonPress = async e => {
    e.preventDefault()

    if (!selectedFiles) {
      handleFileUploadFinished(new Error("You have not selected a file"))
      return
    }

    setFilesToDo(selectedFiles.length)
    setUploading(true)

    let promises = []

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i]
      promises.push(uploadPlateFile(file))
    }

    allProgress(promises, d => setFilesCompleted(d))
      .then(async res => {
        setFilesCompleted(filesCompleted + 1)
        setSelectedFiles(null)
        setSelectedFileText("Select a file..")
        handleFileUploadFinished()
        setUploading(false)
        setFilesToDo(0)
        setFilesCompleted(0)
      })
      .catch(err => {
        setFilesCompleted(filesCompleted + 1)
        setSelectedFiles(null)
        setSelectedFileText("Select a file..")
        setUploading(false)
        setFilesToDo(0)
        setFilesCompleted(0)
      })
  }

  if (uploading)
    return (
      <div>
        <p>
          Completed Uploading {filesCompleted} / {filesToDo} files
        </p>
        <Spinner size={40} intent="primary" />
      </div>
    )

  return (
    <form className="text-gray-800">
      <h3 className="text-gray-900 text-xl font-bold">
        Upload a new TSV Pool Table Data File
      </h3>
      <p>This should be in a TSV file, with 2 header rows. </p>
      <pre className="bp3-code-block text-left w-64 mx-auto">
        <span className="font-bold">HEADER FORMAT:</span> <br />
        PLATE_NAME:DD-MM-YYYY <br />
        Pool Names AB_P1 CD_P2
      </pre>
      <pre className="bp3-code-block text-left w-64 mx-auto">
        <span className="font-bold">ROW FORMAT:</span> <br />
        Sample1 AB_01 CD_01 <br />
        Sample2 AB_02 CD_02
      </pre>
      <div className="flex flex-col w-1/2 mx-auto">
        <FileInput
          text={selectedFileText}
          onInputChange={handleFileChange}
          inputProps={{ multiple: "mutliple" }}
        />
        <button
          onClick={handleButtonPress}
          className="default-btn w-24 mx-auto mt-2"
          type="button"
        >
          Submit
        </button>
      </div>
    </form>
  )
}

export default PoolTableUploadPanel
