import React from "react"
import { Bar } from "react-chartjs-2"

const SummaryIdHistogram = ({ results }) => {
  const datasets = []

  let countMap = {
    "1": 0,
    "2-5": 0,
    "6-10": 0,
    "11-20": 0,
    "20+": 0,
  }
  let allCounts = []

  const set = new Set()
  let filtered = 0
  for (let id in results) {
    const parts = id.split("_")
    set.add(parts[0])

    if (parts[1].length <= 2) {
      const count = +results[id]
      allCounts.push(count)
      if (count === 1) {
        countMap["1"]++
      } else if (count <= 5) {
        countMap["2-5"]++
      } else if (count <= 10) {
        countMap["6-10"]++
      } else if (count <= 20) {
        countMap["11-20"]++
      } else {
        countMap["20+"]++
      }
    } else {
      filtered++
    }
  }

  allCounts.sort((a, b) => a - b)

  datasets.push({
    data: Object.keys(countMap).map(key => {
      return { x: key, y: countMap[key] }
    }),
    backgroundColor: "rgba(75,192,192,0.5)",
    borderColor: "rgba(75,192,192,0.8)",
    hoverBackgroundColor: "rgba(75,192,225,0.8)",
    hoverBorderColor: "rgba(75,192,225,1)",
    borderWidth: 2,
  })

  const data = {
    labels: Object.keys(countMap),
    datasets,
  }

  const median = allCounts[Math.floor(allCounts.length / 2)]
  const average =
    allCounts.reduce((prev, current) => current + prev, 0) / allCounts.length

  return (
    <div className="">
      <Bar
        data={data}
        options={{
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Number of tests run for each unique id",
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                scaleLabel: {
                  display: true,
                  labelString: "Number of unique ids",
                },
              },
            ],
          },
        }}
      />
      <h3 className="mt-2 text-left text-gray-800 font-bold">
        Median Value: {median}
      </h3>
      <h3 className="mt-2 text-left text-gray-800 font-bold">
        Average Value: {Math.round(average)}
      </h3>
      <h3 className="mt-2 text-left text-gray-800 font-bold">
        Filtered due to wrong id number: {filtered}
      </h3>
    </div>
  )
}

export default SummaryIdHistogram
