import React, { useState, useEffect } from "react"
import * as queries from "../../graphql/queries"
import * as mutations from "../../graphql/mutations"
import gql from "graphql-tag"
import { userPoolsClient } from "../../../gatsby-browser"
import { FormGroup, HTMLSelect, Dialog, Icon, Spinner } from "@blueprintjs/core"
import { formatDate } from "../../utils/core"
import AppToaster from "../../utils/appToaster"

const PoolTablePanel = props => {
  const [data, setData] = useState({
    tables: [],
    selected: "",
  })
  const [open, setOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const fetchPoolTables = async () => {
    try {
      const tablesReturned = await userPoolsClient.query({
        query: gql(queries.listPoolLists),
        fetchPolicy: "network-only",
        variables: {
          limit: 100000,
        },
      })

      if (tablesReturned.data.listPoolLists.items.length > 0) {
        let { items } = tablesReturned.data.listPoolLists

        let data = {}
        let lastItemName = ""

        let dataArr = []
        items.forEach(item => {
          let date = formatDate(new Date(item.date))
          const key = `${date} - ${item.name}`
          dataArr.push({
            ...item,
            key,
          })
          lastItemName = key
        })

        dataArr.sort((a, b) => {
          const dA = new Date(a.date)
          const dB = new Date(b.date)

          return dA > dB ? 1 : dA < dB ? -1 : 0
        })

        setData({
          tables: dataArr,
          selected: lastItemName,
        })
      } else {
        setData({
          tables: [],
          selected: "",
        })
      }
    } catch (err) {
      AppToaster.show({
        intent: "danger",
        message: `${err.message}`,
        icon: "error",
      })
    }
  }

  useEffect(() => {
    fetchPoolTables()
  }, [])

  const handleRefresh = () => {
    fetchPoolTables()
  }

  const entry = data.tables.filter(d => d.key === data.selected)

  let content = (!isDeleting && entry && entry.length === 1 && (
    <div>
      <h3 className="text-xl font-bold text-gray-800 text-center">
        {entry[0].name}
      </h3>
      <table className="table-fixed mx-auto">
        <thead>
          <tr>
            <th className="px-4 py-4">Index</th>
            <th className="px-4 py-4">Donor ID</th>
          </tr>
        </thead>
        <tbody>
          {entry[0].resultIds.map((id, index) => {
            return (
              <tr key={`table-row-${index} `} className="px-4">
                <td
                  className={`px-4 py-2 ${
                    index % 2 === 0 ? "bg-gray-300" : ""
                  }`}
                >
                  {index + 1}
                </td>
                <td
                  className={`px-4 py-2 ${
                    index % 2 === 0 ? "bg-gray-300" : ""
                  }`}
                >
                  {id}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )) || <Spinner />

  const handleDeleteButtonPress = () => {
    setIsDeleting(true)
    handleDelete()
  }

  const handleDelete = async () => {
    if (data.selected) {
      try {
        await userPoolsClient.mutate({
          mutation: gql(mutations.deletePoolList),
          variables: {
            input: {
              id: data.selected,
            },
          },
        })
        AppToaster.show({
          intent: "success",
          message: `Deleted successfully`,
          icon: "thumbs-up",
        })
        await fetchPoolTables()
      } catch (err) {
        AppToaster.show({
          intent: "danger",
          message: `${err.message}`,
          icon: "error",
        })
      }
      setIsDeleting(false)
      setOpen(false)
    }
  }

  const tKeys = data.tables.map(d => d.key)

  const dialog = (
    <Dialog isOpen={open} onClose={() => setOpen(false)}>
      <div className="bp3-dialog-header">
        <Icon icon="trash" />
        <h4 className="bp3-heading">Confirm Deletion</h4>
        <button
          className="second-btn w-10 py-1"
          onClick={() => setOpen(false)}
          disabled={isDeleting}
        >
          X
        </button>
      </div>
      <div className="bp3-dialog-body">
        {isDeleting ? (
          <Spinner />
        ) : (
          <p> Are you sure you want to delete {data.selected} ?</p>
        )}
      </div>
      <div className="bp3-dialog-footer">
        <div className="bp3-dialog-footer-actions">
          <button
            className="danger-btn w-24"
            onClick={handleDeleteButtonPress}
            disabled={isDeleting}
          >
            Yes
          </button>
          <button
            className="second-btn w-24"
            onClick={() => setOpen(false)}
            disabled={isDeleting}
          >
            No
          </button>
        </div>
      </div>
    </Dialog>
  )

  return (
    <div>
      {dialog}
      <div className="flex justify-between items-start">
        <button className="default-btn w-24" onClick={handleRefresh}>
          Refresh
        </button>
        <FormGroup label="Select Table">
          <HTMLSelect
            options={tKeys}
            value={data.selected}
            onChange={e =>
              setData({
                tables: [...data.tables],
                selected: e.currentTarget.value,
              })
            }
          />
        </FormGroup>

        <button className="danger-btn w-32" onClick={() => setOpen(true)}>
          Delete this table
        </button>
      </div>
      {content}
    </div>
  )
}

export default PoolTablePanel
