export const unmixpool = (plate, pools, userMap) => {
  const looseIds = []

  for (let pool of pools) {
    const prefix = pool.name.split("_")[0]
    for (let i in pool.resultIds) {
      const id = pool.resultIds[i]
      if (!id.startsWith(prefix)) {
        looseIds.push({
          parent: pool.name,
          id,
        })
        pool.resultIds.splice(i, 1)
      }
    }
  }

  console.log(plate)
  for (let removed of looseIds) {
    let parentRecord = plate.results.filter(
      item => item.donorId === removed.parent
    )
    if (!parentRecord || parentRecord.length === 0) {
      throw new Error("Parent record for " + removed.id + " not found")
    }
    parentRecord = parentRecord[0]
    const prefix = removed.id.split("_")[0]
    const user = userMap[prefix].name
    plate.results.push({
      ...parentRecord,
      user,
      donorId: removed.id,
    })
  }

  return {
    plate,
    pools,
  }
}
