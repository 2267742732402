import React, { useEffect, useState } from "react"
import { Auth } from "aws-amplify"
import * as mutations from "../graphql/mutations"
import * as queries from "../graphql/queries"
import * as cQueries from "../graphql/customQueries"
import { Spinner, H2, Tabs, Tab } from "@blueprintjs/core"
import { navigate } from "@reach/router"
import Layout from "../layouts/layout"
import UserPanel from "../components/admin/UserPanel"
import PlatePanel from "../components/admin/PlatePanel"
import UploadPanel from "../components/admin/UploadPanel"
import SummaryPanel from "../components/admin/SummaryPanel"
import AppToaster from "../utils/appToaster"
import { userPoolsClient } from "../../gatsby-browser"
import gql from "graphql-tag"
import PoolTableUploadPanel from "../components/admin/PoolTableUploadPanel"
import PoolTablePanel from "../components/admin/PoolTablePanel"
import FAQPanel from "../components/admin/FAQSectionPanel"
import SEO from "../components/seo"

const defaultMapState = {
  ALL: {
    id: "abcde",
    name: "all",
    prefix: "all",
  },
}

const Admin = props => {
  const [userMap, setUserMap] = useState(defaultMapState)
  const [verified, setVerified] = useState(false)
  const [plates, setPlates] = useState([])
  const [tab, setTab] = useState("summary")

  const fetchUsers = async () => {
    try {
      const returnedUsers = await userPoolsClient.query({
        query: gql(cQueries.listUsers),
        variables: {
          limit: 1000,
        },
        fetchPolicy: "network-only",
      })
      let map = defaultMapState
      returnedUsers.data.listUsers.items.forEach(u => {
        map[u.prefix] = u
      })
      setUserMap(map)
    } catch (err) {
      AppToaster.show({
        intent: "danger",
        message: `${err.message}`,
        icon: "error",
      })
    }
  }

  const fetchPlates = async () => {
    try {
      const platesReturned = await userPoolsClient.query({
        query: gql(queries.listPlates),
        fetchPolicy: "network-only",
        variables: {
          limit: 100000,
        },
      })
      if (platesReturned.data.listPlates.items.length > 0) {
        setPlates(platesReturned.data.listPlates.items)
      } else {
        setPlates([])
      }
    } catch (err) {
      AppToaster.show({
        intent: "danger",
        message: `${err.message}`,
        icon: "error",
      })
    }
  }

  const createUser = async (name, prefix) => {
    try {
      if (!name || name === "") {
        throw new Error("no user name defined")
      }

      if (!prefix || prefix === "") {
        throw new Error("no user prefix defined")
      }

      await userPoolsClient.mutate({
        mutation: gql(mutations.createUser),
        variables: {
          input: {
            name,
            prefix,
          },
        },
      })
      fetchUsers()
    } catch (err) {
      AppToaster.show({
        intent: "danger",
        message: `${err.message}`,
        icon: "error",
      })
    }
  }

  useEffect(() => {
    Auth.currentSession()
      .then(async session => {
        const groups = session.idToken.payload["cognito:groups"]

        if (groups.includes("admin")) {
          setVerified(true)
          fetchUsers()
          fetchPlates()
        } else {
          navigate("/")
          return
        }
      })
      .catch(() => navigate("/"))
  }, [])

  const handleFileUploadFinished = err => {
    if (err) {
      AppToaster.show({
        intent: "danger",
        message: `${err.message}`,
        icon: "error",
      })
    } else {
      AppToaster.show({
        intent: "success",
        message: `Records uploaded!`,
        icon: "thumbs-up",
      })
    }
  }

  let plateNames = []
  plates.forEach(p => {
    plateNames.push(p.name)
  })

  let content = <Spinner size={40} intent="primary" />

  if (verified) {
    content = (
      <div className="mx-auto max-w-full my-20">
        <div className="flex items-baseline mb-8 justify-between">
          <H2 className="mb-10">Welcome, admin!</H2>
          <button
            className="default-btn w-40"
            onClick={() => navigate("/admin-results")}
          >
            View Results
          </button>
        </div>
        <Tabs
          id="tabs"
          className="text-"
          selectedTabId={tab}
          onChange={t => setTab(t)}
        >
          <Tab
            id="summary"
            title="📖 Summary "
            panel={
              <div className="bg-gray-200 p-4 rounded-lg shadow-lg mt-10">
                <SummaryPanel userMap={userMap} />
              </div>
            }
          />
          <Tab
            id="file"
            title="🍽️ Plates"
            panel={
              <div className="bg-gray-200 p-4 rounded-lg shadow-lg mt-10">
                {/* <FilePanel handleFileUploadFinished={handleFileUploadFinished} userMap={userMap} plateNames={plateNames} /> */}
                <div className="bg-white p-4 rounded-lg shadow-lg mt-10">
                  <PlatePanel plates={plates} onRefresh={fetchPlates} />
                </div>
              </div>
            }
          />
          <Tab
            id="pool"
            title="🎱 Pool Tables "
            panel={
              <div className="bg-gray-200 p-4 rounded-lg shadow-lg mt-10">
                <PoolTableUploadPanel />
                <div className="bg-white p-4 rounded-lg shadow-lg mt-10">
                  <PoolTablePanel />
                </div>
              </div>
            }
          />
          <Tab
            id="upload"
            title="📁 Upload"
            panel={
              <div className="bg-gray-200 p-4 rounded-lg shadow-lg mt-10">
                <UploadPanel
                  handleFileUploadFinished={handleFileUploadFinished}
                  userMap={userMap}
                  plateNames={plateNames}
                />
              </div>
            }
          />
          <Tab
            id="user"
            title="👤 User Management "
            panel={
              <div className="bg-gray-200 p-4 rounded-lg shadow-lg mt-10">
                <UserPanel onCreateUser={createUser} />
              </div>
            }
          />
          <Tab
            id="faq"
            title="🙋 FAQs"
            panel={
              <div className="bg-gray-200 p-4 rounded-lg shadow-lg mt-10">
                <FAQPanel />
              </div>
            }
          />
          <Tabs.Expander />
        </Tabs>
      </div>
    )
  }

  return (
    <Layout>
      <SEO title="Admin" />
      {content}
    </Layout>
  )
}

export default Admin
