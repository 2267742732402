import React, { useState } from "react"
import {
  parsePlateData,
  parsePoolTableData,
} from "../../utils/readers/plate_reader"
import { unmixpool } from "../../utils/pools"
import { uploadPlateData, uploadPools } from "../../utils/readers/uploader"
import { FileInput, Spinner, FormGroup } from "@blueprintjs/core"
import AppToaster from "../../utils/appToaster"

const DEFAULT_PLATE_MESSAGE = "Select a plate file..."
const DEFAULT_POOL_MESSAGE = "Select one or more pool files..."

const UploadPanel = ({ handleFileUploadFinished, userMap, plateNames }) => {
  const [selectedPlateFile, setSelectedPlateFile] = useState(null)
  const [selectedPoolFiles, setSelectedPoolFiles] = useState(null)
  const [plateSelectText, setPlateSelectText] = useState(DEFAULT_PLATE_MESSAGE)
  const [poolSelectText, setPoolSelectText] = useState(DEFAULT_POOL_MESSAGE)
  const [uploading, setUploading] = useState(false)

  const handlePlateFileChange = e => {
    const files = e.target.files
    if (files[0]) {
      setPlateSelectText(files[0].name)
      setSelectedPlateFile(files[0])
    }
  }

  const handlePoolFilesChange = e => {
    const files = e.target.files
    if (files) {
      const name = files.length > 1 ? `${files[0].name} + more` : files[0].name
      setPoolSelectText(name)
      setSelectedPoolFiles(files)
    }
  }

  const parsePlateFile = async file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onerror = err => {
        err.message = `${file.name}: ${err.message}`
        reject(err)
      }

      reader.onload = async data => {
        try {
          const res = await parsePlateData(data, plateNames, userMap)
          resolve(res)
        } catch (err) {
          handleFileUploadFinished(err)
          reject(err.message)
        }
      }

      reader.readAsText(file, "utf-8")
    })
  }

  const parsePoolTable = async file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onerror = err => {
        err.message = `${file.name}: ${err.message}`
        reject(err)
      }

      reader.onload = async data => {
        try {
          const res = await parsePoolTableData(data)
          resolve(res)
        } catch (err) {
          handleFileUploadFinished(err)
          reject(err.message)
        }
      }

      reader.readAsText(file, "utf-8")
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setUploading(true)

    handleFiles()
  }

  const handleFiles = async () => {
    try {
      if (!selectedPlateFile) {
        throw new Error("You have not selected a plate file")
      }

      const plate = await parsePlateFile(selectedPlateFile)

      const pools = []
      if (selectedPoolFiles) {
        for (let i = 0; i < selectedPoolFiles.length; i++) {
          const poolsFromFile = await parsePoolTable(selectedPoolFiles[i])
          pools.push(...poolsFromFile)
        }
      }

      const unmixed = unmixpool(plate, pools, userMap)

      await uploadPlateData(unmixed.plate)
      await uploadPools(unmixed.pools)

      AppToaster.show({
        intent: "success",
        message: `Files Uploaded.`,
        icon: "thumbs-up",
      })
    } catch (err) {
      handleFileUploadFinished(err)
    }
    setSelectedPlateFile(null)
    setSelectedPoolFiles(null)
    setPlateSelectText(DEFAULT_PLATE_MESSAGE)
    setPoolSelectText(DEFAULT_POOL_MESSAGE)
    setUploading(false)
  }

  if (uploading)
    return (
      <div>
        <Spinner size={40} intent="primary" />
      </div>
    )

  return (
    <form
      className="text-center mx-auto text-gray-800 my-10 "
      onSubmit={handleSubmit}
    >
      <h3 className="font-bold text-gray-900 text-xl">Upload</h3>
      <div className="flex flex-col w-1/2 mx-auto mt-6">
        <div className="mx-auto">
          <FormGroup inline label="Plate File*">
            <FileInput
              text={plateSelectText}
              onInputChange={handlePlateFileChange}
            />
          </FormGroup>
        </div>
        <div className="mx-auto">
          <FormGroup inline label="Pool Files">
            <FileInput
              text={poolSelectText}
              onInputChange={handlePoolFilesChange}
              inputProps={{ multiple: "mutliple" }}
            />
          </FormGroup>
        </div>

        <button type="submit" className="default-btn w-32 mt-6 mx-auto">
          Submit
        </button>
      </div>
    </form>
  )
}

export default UploadPanel
