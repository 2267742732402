export const listPlatesUser = /* GraphQL */ `
  query ListPlates(
    $id: ID
    $filter: ModelPlateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPlates(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        date
        results {
          items {
            id
            donorId
            user
            N1
            N2
            N3
            RP
            valid
            summary
          }
          nextToken
        }
      }
      nextToken
    }
  }
`

export const listPlatesSummary = /* GraphQL */ `
  query ListPlates(
    $id: ID
    $filter: ModelPlateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPlates(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        date
        results {
          items {
            donorId
            user
            N1
            N2
            N3
            RP
          }
          nextToken
        }
      }
      nextToken
    }
  }
`

export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        prefix
        addresses {
          items {
            id
            address
            name
          }
          nextToken
        }
      }
      nextToken
    }
  }
`
