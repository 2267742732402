import { nanoid } from "nanoid"
import AppToaster from "../appToaster"

export const parsePlateData = async (data, plateNames, userMap) => {
  const { result } = data.currentTarget

  let lines = result.split("\n")

  let lineCount = 0
  let plateName = ""
  let plateDate = ""

  let header = lines[0]

  let sections = header.split(":")
  if (sections.length !== 2) {
    throw new Error("expected header in the format  - NAME:DD-MM-YYYY")
  }

  plateName = sections[0]

  if (plateNames.includes(plateName)) {
    throw new Error(
      `Cannot create plate - plate with name ${plateName} already exists.`
    )
  }

  let dateParts = sections[1].split("-")
  if (dateParts.length !== 3) {
    throw new Error("expected 3 sections for the date - DD-MM-YYYY")
  }

  plateDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0])

  const newPlate = {
    id: nanoid(),
    name: plateName,
    date: plateDate,
    results: [],
  }

  for (let j = 0; j < lines.length; j++) {
    const line = lines[j]

    lineCount++

    let parts = line.split("\t")
    if (parts.length === 8 && lineCount !== 1) {
      //valid lines!
      let recordDetails = {
        id: nanoid(),
        resultPlateId: newPlate.id,
        donorId: parts[1],
        N1: parts[2],
        N2: parts[3],
        N3: parts[4],
        RP: parts[5],
        valid: parts[6],
        summary: parts[7],
      }

      let usernameId = recordDetails.donorId.split("_")[0]

      if (!userMap[usernameId])
        throw new Error("user " + usernameId + " does not exist")
      recordDetails.user = userMap[usernameId].name

      newPlate.results.push(recordDetails)
    }
  }

  return newPlate
}

export const parsePoolTableData = async data => {
  const { result } = data.currentTarget

  let lines = result.split("\n")

  let header = lines[0]

  let sections = header.split(":")
  if (sections.length !== 2) {
    throw new Error("expected header in the format  - NAME:DD-MM-YYYY")
  }

  let dateParts = sections[1].split("-")
  if (dateParts.length !== 3) {
    throw new Error("expected 3 sections for the date - DD-MM-YYYY")
  }

  const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0])

  let resultData = {}

  let poolNames = lines[1].split("\t")
  for (let i = 1; i < poolNames.length; i++) {
    if (poolNames[i].trim() !== "") {
      resultData[i] = {
        name: poolNames[i].trim(),
        donorIds: [],
      }
    }
  }

  if (lines.length < 3) {
    throw new Error("expected at least one entry after the 2 header lines")
  }

  for (let j = 2; j < lines.length; j++) {
    const line = lines[j]

    if (line.trim() !== "") {
      const donorIds = line.trim().split("\t")

      for (let i = 1; i < donorIds.length; i++) {
        if (donorIds[i] !== "") {
          resultData[i].donorIds.push(donorIds[i].trim())
        }
      }
    }
  }

  let keys = Object.keys(resultData)
  let pools = []
  for (let j in keys) {
    const { name, donorIds } = resultData[keys[j]]
    try {
      pools.push({
        id: `${sections[1].trim()} - ${name.trim()}`,
        date,
        name,
        resultIds: donorIds,
      })
    } catch (e) {
      AppToaster.show({
        intent: "danger",
        message: `Something went wrong. You may have already uploaded these pool ids for this date.`,
        icon: "error",
      })
    }
  }

  return pools
}
