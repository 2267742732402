import React from "react"
import { findColor } from "../../utils/results"
import { HTMLTable } from "@blueprintjs/core"

const AdminResultTable = ({ results }) => {
  if (!results) return null

  const rows = results.map(result => {
    const { id, donorId, user, N1, N2, N3, RP, valid, summary } = result

    const c = findColor(result)
    const cellStyle = { color: c }

    return (
      <tr key={id}>
        <td style={cellStyle}>{donorId}</td>
        <td style={cellStyle}>{user}</td>
        <td style={cellStyle}>{N1}</td>
        <td style={cellStyle}>{N2}</td>
        <td style={cellStyle}>{N3}</td>
        <td style={cellStyle}>{RP}</td>
        <td style={cellStyle}>{valid}</td>
        <td style={cellStyle}>{summary}</td>
      </tr>
    )
  })

  return (
    <div>
      <HTMLTable striped interactive small className="w-full">
        <thead>
          <tr>
            <th>Donor ID</th>
            <th>User</th>
            <th>N1</th>
            <th>N2</th>
            <th>N3</th>
            <th>RP</th>
            <th>Valid?</th>
            <th>Summary</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </HTMLTable>
    </div>
  )
}

export default AdminResultTable
