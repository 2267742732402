import React, { useState, useEffect } from "react"
import * as mutations from "../../graphql/mutations"
import * as cQueries from "../../graphql/customQueries"
import gql from "graphql-tag"
import { userPoolsClient } from "../../../gatsby-browser"
import {
  HTMLTable,
  FormGroup,
  InputGroup,
  Icon,
  Dialog,
} from "@blueprintjs/core"
import AppToaster from "../../utils/appToaster"
import { nanoid } from "nanoid"

const UserPanel = ({ onCreateUser }) => {
  const [userMap, setUserMap] = useState({})
  const [name, setName] = useState("")
  const [prefix, setPrefix] = useState("")
  const [email, setEmail] = useState("")
  const [emailName, setEmailName] = useState("")
  const [open, setOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState({
    id: "",
    name: "",
    addresses: [],
  })

  const fetchUsers = async () => {
    try {
      const returnedUsers = await userPoolsClient.query({
        query: gql(cQueries.listUsers),
        variables: {
          limit: 1000,
        },
        fetchPolicy: "network-only",
      })
      let map = {}
      returnedUsers.data.listUsers.items.forEach(u => {
        map[u.prefix] = u
      })

      let ordered = {}
      Object.keys(map)
        .sort()
        .forEach(key => {
          ordered[key] = map[key]
        })
      setUserMap(ordered)
      if (selectedUser.id !== "") {
        Object.keys(map).forEach(key => {
          let { id, name, addresses } = map[key]
          if (id === selectedUser.id) {
            setSelectedUser({
              id,
              name,
              addresses: addresses.items,
            })
          }
        })
      }
    } catch (err) {
      AppToaster.show({
        intent: "danger",
        message: `${err.message}`,
        icon: "error",
      })
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  const handleDialogOpen = (id, name, addresses) => {
    setSelectedUser({
      id,
      name,
      addresses,
    })
    setOpen(true)
  }

  const handleDeleteEmail = async id => {
    try {
      await userPoolsClient.mutate({
        mutation: gql(mutations.deleteEmailAddress),
        variables: {
          input: {
            id,
          },
        },
      })

      AppToaster.show({
        message: "Email address successfully deleted",
        intent: "success",
        icon: "thumbs-up",
      })
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning",
      })
    }

    fetchUsers()
  }

  const handleAddEmail = async () => {
    let record = {
      id: nanoid(),
      address: email,
      name: emailName,
      emailAddressUserId: selectedUser.id,
    }

    try {
      await userPoolsClient.mutate({
        mutation: gql(mutations.createEmailAddress),
        variables: {
          input: record,
        },
      })

      AppToaster.show({
        message: "Email address successfully added",
        intent: "success",
        icon: "thumbs-up",
      })
    } catch (e) {
      AppToaster.show({
        message: e.message,
        intent: "danger",
        icon: "warning",
      })
    }

    setEmail("")
    setEmailName("")
    fetchUsers()
  }

  const handleDialogClose = () => {
    setOpen(false)
    setSelectedUser({
      id: "",
      name: "",
      addresses: [],
    })
  }

  const dialog = (
    <Dialog isOpen={open} onClose={handleDialogClose}>
      <div className="bp3-dialog-header py-2">
        <Icon icon="envelope" />
        <h4 className="bp3-heading">Manage emails for {selectedUser.name}</h4>
        <button className="second-btn w-10" onClick={handleDialogClose}>
          X
        </button>
      </div>
      <div className="bp3-dialog-body">
        <FormGroup label="address">
          <InputGroup
            value={email}
            onChange={e => setEmail(e.currentTarget.value)}
            className="flex-grow mr-2"
          />
        </FormGroup>
        <FormGroup label="name">
          <InputGroup
            value={emailName}
            onChange={e => setEmailName(e.currentTarget.value)}
            className="flex-grow mr-2"
          />
        </FormGroup>
        <button className="default-btn w-24 mb-6" onClick={handleAddEmail}>
          Add
        </button>
        <table className="table-fixed w-full text-left">
          <thead>
            <tr>
              <th>Email</th>
              <th>Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {selectedUser.addresses.map(({ id, address, name }) => {
              return (
                <tr key={id}>
                  <td>{address}</td>
                  <td>{name}</td>
                  <td>
                    <button
                      onClick={() => handleDeleteEmail(id)}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-4 rounded"
                    >
                      <Icon icon="trash" />
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="bp3-dialog-footer">
        <div className="bp3-dialog-footer-actions">
          <button className="second-btn w-24" onClick={handleDialogClose}>
            Close
          </button>
        </div>
      </div>
    </Dialog>
  )

  let done = []

  const users = Object.keys(userMap).map(key => {
    const { id, prefix, name, addresses } = userMap[key]
    if (done.includes(name)) return null
    done.push(name)
    let emails = ""
    let names = ""
    if (addresses && addresses.items && addresses.items.length > 0) {
      const { items } = addresses
      emails = items.map(item => item.address).join(", ")
      names = items.map(item => item.name ? item.name : '').join(', ')
    }

    let emailDisplay =
      emails.length > 30 ? emails.substring(0, 30) + "..." : emails

    let nameDisplay = names.length > 20 ? names.substring(0,20) + "..." : names 

    return (
      <tr key={key}>
        <td>{prefix}</td>
        <td>{name}</td>
        <td>{emailDisplay}</td>
        <td>{nameDisplay}</td>
        <td>
          <button
            icon="add"
            intent="success"
            className="mr-2 bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-4 rounded"
            onClick={() => handleDialogOpen(id, name, addresses.items)}
          >
            <Icon icon="add" />
          </button>
        </td>
      </tr>
    )
  })

  const handleCreateUser = () => {
    onCreateUser(name, prefix)
    setName("")
    setPrefix("")
  }

  return (
    <>
      {dialog}
      <div className="mx-auto mt-10 text-center">
        <p className="font-semibold text-lg mb-10">
          Below are the current donorID prefixes allowed: expected format
          PREFIX_##
        </p>
        <HTMLTable className="mx-auto text-center">
          <thead>
            <tr>
              <th>PREFIX</th>
              <th>Username</th>
              <th>Emails</th>
              <th>Names</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{users}</tbody>
        </HTMLTable>
        <hr />
        <h3 className="mt-10">Create a new user-prefix combination</h3>
        <p>
          ** This does NOT create a user account - it simply links a certain
          prefix to a user account name
        </p>
        <div className="text-center mx-auto w-1/2 mt-10">
          <FormGroup label="User account name" labelInfo="(case sensitive)">
            <InputGroup
              value={name}
              onChange={e => setName(e.currentTarget.value)}
            />
          </FormGroup>
          <FormGroup label="prefix">
            <InputGroup
              value={prefix}
              onChange={e => setPrefix(e.currentTarget.value.toUpperCase())}
            />
          </FormGroup>
          <button onClick={handleCreateUser} className="default-btn w-38">
            Create User
          </button>
        </div>
      </div>
    </>
  )
}

export default UserPanel
