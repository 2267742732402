/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const batchAddResults = /* GraphQL */ `
  mutation BatchAddResults($results: [CreateResultInput]) {
    batchAddResults(results: $results) {
      id
      donorId
      user
      N1
      N2
      N3
      RP
      valid
      summary
      plate {
        id
        name
        date
        results {
          nextToken
        }
      }
    }
  }
`
export const createResult = /* GraphQL */ `
  mutation CreateResult(
    $input: CreateResultInput!
    $condition: ModelResultConditionInput
  ) {
    createResult(input: $input, condition: $condition) {
      id
      donorId
      user
      N1
      N2
      N3
      RP
      valid
      summary
      plate {
        id
        name
        date
        results {
          nextToken
        }
      }
    }
  }
`
export const updateResult = /* GraphQL */ `
  mutation UpdateResult(
    $input: UpdateResultInput!
    $condition: ModelResultConditionInput
  ) {
    updateResult(input: $input, condition: $condition) {
      id
      donorId
      user
      N1
      N2
      N3
      RP
      valid
      summary
      plate {
        id
        name
        date
        results {
          nextToken
        }
      }
    }
  }
`
export const deleteResult = /* GraphQL */ `
  mutation DeleteResult(
    $input: DeleteResultInput!
    $condition: ModelResultConditionInput
  ) {
    deleteResult(input: $input, condition: $condition) {
      id
      donorId
      user
      N1
      N2
      N3
      RP
      valid
      summary
      plate {
        id
        name
        date
        results {
          nextToken
        }
      }
    }
  }
`
export const createPoolList = /* GraphQL */ `
  mutation CreatePoolList(
    $input: CreatePoolListInput!
    $condition: ModelPoolListConditionInput
  ) {
    createPoolList(input: $input, condition: $condition) {
      id
      name
      date
      resultIds
    }
  }
`
export const updatePoolList = /* GraphQL */ `
  mutation UpdatePoolList(
    $input: UpdatePoolListInput!
    $condition: ModelPoolListConditionInput
  ) {
    updatePoolList(input: $input, condition: $condition) {
      id
      name
      date
      resultIds
    }
  }
`
export const deletePoolList = /* GraphQL */ `
  mutation DeletePoolList(
    $input: DeletePoolListInput!
    $condition: ModelPoolListConditionInput
  ) {
    deletePoolList(input: $input, condition: $condition) {
      id
      name
      date
      resultIds
    }
  }
`
export const createPlate = /* GraphQL */ `
  mutation CreatePlate(
    $input: CreatePlateInput!
    $condition: ModelPlateConditionInput
  ) {
    createPlate(input: $input, condition: $condition) {
      id
      name
      date
      results {
        items {
          id
          donorId
          user
          N1
          N2
          N3
          RP
          valid
          summary
        }
        nextToken
      }
    }
  }
`
export const updatePlate = /* GraphQL */ `
  mutation UpdatePlate(
    $input: UpdatePlateInput!
    $condition: ModelPlateConditionInput
  ) {
    updatePlate(input: $input, condition: $condition) {
      id
      name
      date
      results {
        items {
          id
          donorId
          user
          N1
          N2
          N3
          RP
          valid
          summary
        }
        nextToken
      }
    }
  }
`
export const deletePlate = /* GraphQL */ `
  mutation DeletePlate(
    $input: DeletePlateInput!
    $condition: ModelPlateConditionInput
  ) {
    deletePlate(input: $input, condition: $condition) {
      id
      name
      date
      results {
        items {
          id
          donorId
          user
          N1
          N2
          N3
          RP
          valid
          summary
        }
        nextToken
      }
    }
  }
`
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      prefix
      addresses {
        items {
          id
          address
          name
        }
        nextToken
      }
    }
  }
`
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      prefix
      addresses {
        items {
          id
          address
          name
        }
        nextToken
      }
    }
  }
`
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      prefix
      addresses {
        items {
          id
          address
          name
        }
        nextToken
      }
    }
  }
`
export const createEmailAddress = /* GraphQL */ `
  mutation CreateEmailAddress(
    $input: CreateEmailAddressInput!
    $condition: ModelEmailAddressConditionInput
  ) {
    createEmailAddress(input: $input, condition: $condition) {
      id
      address
      name
      user {
        id
        name
        prefix
        addresses {
          nextToken
        }
      }
    }
  }
`
export const updateEmailAddress = /* GraphQL */ `
  mutation UpdateEmailAddress(
    $input: UpdateEmailAddressInput!
    $condition: ModelEmailAddressConditionInput
  ) {
    updateEmailAddress(input: $input, condition: $condition) {
      id
      address
      name
      user {
        id
        name
        prefix
        addresses {
          nextToken
        }
      }
    }
  }
`
export const deleteEmailAddress = /* GraphQL */ `
  mutation DeleteEmailAddress(
    $input: DeleteEmailAddressInput!
    $condition: ModelEmailAddressConditionInput
  ) {
    deleteEmailAddress(input: $input, condition: $condition) {
      id
      address
      name
      user {
        id
        name
        prefix
        addresses {
          nextToken
        }
      }
    }
  }
`
export const createIndexStats = /* GraphQL */ `
  mutation CreateIndexStats(
    $input: CreateIndexStatsInput!
    $condition: ModelIndexStatsConditionInput
  ) {
    createIndexStats(input: $input, condition: $condition) {
      id
      tests
      people
      centres
      positives
      dateCounts
    }
  }
`
export const updateIndexStats = /* GraphQL */ `
  mutation UpdateIndexStats(
    $input: UpdateIndexStatsInput!
    $condition: ModelIndexStatsConditionInput
  ) {
    updateIndexStats(input: $input, condition: $condition) {
      id
      tests
      people
      centres
      positives
      dateCounts
    }
  }
`
export const deleteIndexStats = /* GraphQL */ `
  mutation DeleteIndexStats(
    $input: DeleteIndexStatsInput!
    $condition: ModelIndexStatsConditionInput
  ) {
    deleteIndexStats(input: $input, condition: $condition) {
      id
      tests
      people
      centres
      positives
      dateCounts
    }
  }
`
export const createFaqSection = /* GraphQL */ `
  mutation CreateFaqSection(
    $input: CreateFAQSectionInput!
    $condition: ModelFAQSectionConditionInput
  ) {
    createFAQSection(input: $input, condition: $condition) {
      id
      open
      text
    }
  }
`
export const updateFaqSection = /* GraphQL */ `
  mutation UpdateFaqSection(
    $input: UpdateFAQSectionInput!
    $condition: ModelFAQSectionConditionInput
  ) {
    updateFAQSection(input: $input, condition: $condition) {
      id
      open
      text
    }
  }
`
export const deleteFaqSection = /* GraphQL */ `
  mutation DeleteFaqSection(
    $input: DeleteFAQSectionInput!
    $condition: ModelFAQSectionConditionInput
  ) {
    deleteFAQSection(input: $input, condition: $condition) {
      id
      open
      text
    }
  }
`
export const createSwabRequest = /* GraphQL */ `
  mutation CreateSwabRequest(
    $input: CreateSwabRequestInput!
    $condition: ModelSwabRequestConditionInput
  ) {
    createSwabRequest(input: $input, condition: $condition) {
      id
      user
      amount
    }
  }
`
export const updateSwabRequest = /* GraphQL */ `
  mutation UpdateSwabRequest(
    $input: UpdateSwabRequestInput!
    $condition: ModelSwabRequestConditionInput
  ) {
    updateSwabRequest(input: $input, condition: $condition) {
      id
      user
      amount
    }
  }
`
export const deleteSwabRequest = /* GraphQL */ `
  mutation DeleteSwabRequest(
    $input: DeleteSwabRequestInput!
    $condition: ModelSwabRequestConditionInput
  ) {
    deleteSwabRequest(input: $input, condition: $condition) {
      id
      user
      amount
    }
  }
`
export const createChartDataRecord = /* GraphQL */ `
  mutation CreateChartDataRecord(
    $input: CreateChartDataRecordInput!
    $condition: ModelChartDataRecordConditionInput
  ) {
    createChartDataRecord(input: $input, condition: $condition) {
      id
      date
      positive
      negative
      partial
      all
    }
  }
`
export const updateChartDataRecord = /* GraphQL */ `
  mutation UpdateChartDataRecord(
    $input: UpdateChartDataRecordInput!
    $condition: ModelChartDataRecordConditionInput
  ) {
    updateChartDataRecord(input: $input, condition: $condition) {
      id
      date
      positive
      negative
      partial
      all
    }
  }
`
export const deleteChartDataRecord = /* GraphQL */ `
  mutation DeleteChartDataRecord(
    $input: DeleteChartDataRecordInput!
    $condition: ModelChartDataRecordConditionInput
  ) {
    deleteChartDataRecord(input: $input, condition: $condition) {
      id
      date
      positive
      negative
      partial
      all
    }
  }
`
