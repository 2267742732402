export const exportToCSV = (data, name) => {
  let header = "Username,id,type," + data.dates.join(",")
  let rows = Object.keys(data.results).map(username => {
    let value = data.results[username]
    let posRow = [
      username,
      value.id,
      "POS",
      ...Object.keys(value)
        .filter(k => k !== "id")
        .map(k => value[k].POS),
    ]
    let ppRow = [
      username,
      value.id,
      "PP",
      ...Object.keys(value)
        .filter(k => k !== "id")
        .map(k => value[k].PP),
    ]
    let negRow = [
      username,
      value.id,
      "NEG",
      ...Object.keys(value)
        .filter(k => k !== "id")
        .map(k => value[k].NEG),
    ]
    let allRow = [
      username,
      value.id,
      "ALL",
      ...Object.keys(value)
        .filter(k => k !== "id")
        .map(k => value[k].ALL),
    ]
    return [posRow, ppRow, negRow, allRow]
  })

  let csvContent =
    "data:text/csv;charset=utf-8," +
    header +
    "\n" +
    rows
      .flat()
      .map(e => e.join(","))
      .join("\n")

  const encodedUri = encodeURI(csvContent)
  let link = document.createElement("a")
  link.setAttribute("href", encodedUri)
  link.setAttribute("download", name)
  document.body.appendChild(link)

  link.click()
}

export const exportSingleDateToCSV = (data, name, date) => {
  let header = "Date,Lab,Customer,Test count,Positive,Retest,Negative"
  let rows = Object.keys(data).map(id => {
    if (data[id].ALL === 0) return []
    return [
      date,
      "SBL",
      id,
      data[id].ALL,
      data[id].POS,
      data[id].PP,
      data[id].NEG,
    ]
  })

  let csvContent =
    "data:text/csv;charset=utf-8," +
    header +
    "\n" +
    rows
      .filter(arr => arr.length !== 0)
      .map(e => e.join(","))
      .join("\n")

  const encodedUri = encodeURI(csvContent)
  let link = document.createElement("a")
  link.setAttribute("href", encodedUri)
  link.setAttribute("download", name)
  document.body.appendChild(link)

  link.click()
}
