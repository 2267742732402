import React, { useEffect, useState } from "react"
import {
  FormGroup,
  HTMLSelect,
  Dialog,
  Icon,
  Spinner,
  InputGroup,
} from "@blueprintjs/core"
import AdminResultsTable from "./AdminResultTable"
import * as queries from "../../graphql/queries"
import * as mutations from "../../graphql/mutations"
import { userPoolsClient } from "../../../gatsby-browser"
import gql from "graphql-tag"
import AppToaster from "../../utils/appToaster"
import { formatDate } from "../../utils/core"

function fetchResultsForPlate(plateId) {
  return new Promise(async (resolve, reject) => {
    if (!plateId || plateId === "") {
      reject(new Error("No plate id provided"))
    }

    const allResults = await userPoolsClient.query({
      query: gql(queries.getPlate),
      variables: {
        id: plateId,
      },
      fetchPolicy: "network-only",
    })

    if (allResults.data.getPlate.results) {
      let res = [...allResults.data.getPlate.results.items]
      const sorted = res.sort((a, b) =>
        a.donorId > b.donorId ? 1 : a.donorId < b.donorId ? -1 : 0
      )
      resolve(sorted)
    }

    resolve([])
  })
}

const CONFIRM_PHRASE =
  "Yup, I want to do this and will not hold Sam responsible for anything that goes wrong"

const PlatePanel = ({ plates, onRefresh }) => {
  const [isDeleting, setDeleting] = useState(false)
  const [confirmPhrase, setConfirmPhrase] = useState("")
  const [refreshing, setRefreshing] = useState(true)
  const [selectedPlateName, setSelectedPlate] = useState("")
  const [results, setResults] = useState([])
  const [open, setOpen] = useState(false)
  const [openDeleteAll, setOpenDeleteAll] = useState(false)

  useEffect(() => {
    if (plates.length > 0) {
      setSelectedPlate(plates[0].name)
    }
  }, [plates])

  const deletePlate = async (plateId, results) => {
    return new Promise(async (resolve, reject) => {
      try {
        await results.forEach(async result => {
          await userPoolsClient.mutate({
            mutation: gql(mutations.deleteResult),
            variables: {
              input: {
                id: result.id,
              },
            },
          })
        })

        await userPoolsClient.mutate({
          mutation: gql(mutations.deletePlate),
          variables: {
            input: {
              id: plateId,
            },
          },
        })

        resolve(results.length)
      } catch (err) {
        reject(err)
      }
    })
  }

  const handleSingleDelete = async () => {
    let plate
    plates.forEach(p => {
      if (p.name === selectedPlateName) {
        plate = p
      }
    })

    setDeleting(true)
    await deletePlate(plate.id, results).catch(err => {
      AppToaster.show({
        intent: "danger",
        message: `${err.message}`,
        icon: "error",
      })
    })

    AppToaster.show({
      intent: "success",
      message: `${selectedPlateName} deleted`,
      icon: "thumbs-up",
    })
    setDeleting(false)
    setRefreshing(true)
    setOpen(false)
    setResults([])
    onRefresh()
  }

  const dialog = (
    <Dialog isOpen={open} onClose={() => setOpen(false)}>
      <div className="bp3-dialog-header">
        <Icon icon="trash" />
        <h4 className="bp3-heading">Confirm Deletion</h4>
        <button
          className="second-btn w-10 py-1"
          onClick={() => setOpen(false)}
          disabled={isDeleting}
        >
          X
        </button>
      </div>
      <div className="bp3-dialog-body">
        Are you sure you want to delete {selectedPlateName} ?
      </div>
      <div className="bp3-dialog-footer">
        <div className="bp3-dialog-footer-actions">
          <button
            className="danger-btn w-24"
            onClick={handleSingleDelete}
            disabled={isDeleting}
          >
            Yes
          </button>
          <button
            className="second-btn w-24"
            onClick={() => setOpen(false)}
            disabled={isDeleting}
          >
            No
          </button>
        </div>
      </div>
    </Dialog>
  )

  const handleDeleteAll = () => {
    setDeleting(true)

    let getResultsPromises = []

    plates.forEach(plate => {
      getResultsPromises.push(fetchResultsForPlate(plate.id))
    })

    Promise.all(getResultsPromises).then(async resultsLists => {
      await resultsLists.forEach(async results => {
        results.forEach(async result => {
          userPoolsClient.mutate({
            mutation: gql(mutations.deleteResult),
            variables: {
              input: {
                id: result.id,
              },
            },
          })
        })
      })

      for (let i = 0; i < plates.length; i++) {
        await userPoolsClient.mutate({
          mutation: gql(mutations.deletePlate),
          variables: {
            input: {
              id: plates[i].id,
            },
          },
        })
      }

      setDeleting(false)
      setRefreshing(true)
      setOpenDeleteAll(false)
      setResults([])
      onRefresh()
    })
  }

  const deleteAllDialog = (
    <Dialog isOpen={openDeleteAll} onClose={() => setOpenDeleteAll(false)}>
      <div className="bp3-dialog-header">
        <Icon icon="trash" />
        <h4 className="bp3-heading">DELETE ALL THE FILES!</h4>
        <button
          className="second-btn w-10 py-1"
          onClick={() => setOpenDeleteAll(false)}
          disabled={isDeleting}
        >
          X
        </button>
      </div>
      <div className="bp3-dialog-body">
        {isDeleting ? (
          <Spinner />
        ) : (
          <>
            <p>
              Type "<span className="text-blue-500">{CONFIRM_PHRASE}</span>" to
              confirm you actually want to do this.
            </p>
            <FormGroup>
              <InputGroup
                value={confirmPhrase}
                onChange={e => setConfirmPhrase(e.currentTarget.value)}
              />
            </FormGroup>
          </>
        )}
      </div>
      <div className="bp3-dialog-footer">
        <div className="bp3-dialog-footer-actions">
          <button
            className="danger-btn w-24"
            onClick={handleDeleteAll}
            disabled={confirmPhrase !== CONFIRM_PHRASE || isDeleting}
          >
            Do it.
          </button>
          <button
            className="second-btn w-24"
            onClick={() => setOpenDeleteAll(false)}
            disabled={isDeleting}
          >
            No
          </button>
        </div>
      </div>
    </Dialog>
  )

  useEffect(() => {
    if (selectedPlateName && plates) {
      let plateId = ""
      plates.forEach(p => {
        if (p.name === selectedPlateName) {
          plateId = p.id
        }
      })

      if (plateId && plateId !== "") {
        fetchResultsForPlate(plateId)
          .then(results => {
            setResults(results)
            setRefreshing(false)
          })
          .catch(err => {
            AppToaster.show({
              intent: "danger",
              message: `${err.message}`,
              icon: "error",
            })
          })
      } else {
        setRefreshing(false)
      }
    } else {
      setRefreshing(false)
    }
  }, [selectedPlateName, plates])

  if (refreshing) return <Spinner />

  const sP = plates.filter(p => p.name === selectedPlateName)[0]

  return (
    <>
      {dialog}
      {deleteAllDialog}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2 className="text-gray-800 text-2xl font-bold">Plates</h2>
          <button className="default-btn w-24" onClick={onRefresh}>
            Refresh
          </button>
        </div>
        <div className="flex justify-between mb-6">
          <FormGroup label="Select Plate">
            <HTMLSelect
              options={
                plates.length === 0 ? [] : plates.map(e => e.name).sort()
              }
              value={selectedPlateName}
              onChange={e => setSelectedPlate(e.currentTarget.value)}
            />
          </FormGroup>
          {sP && <p>{formatDate(new Date(sP.date))} </p>}
          <div className="flex mt-2 ">
            <button
              onClick={() => setOpen(true)}
              className="danger-btn w-32 mr-2"
            >
              Delete this plate
            </button>
            <button
              onClick={() => setOpenDeleteAll(true)}
              className="danger-btn w-32 "
            >
              Delete all plates
            </button>
          </div>
        </div>
      </div>
      <AdminResultsTable results={results} />
    </>
  )
}

export default PlatePanel
