import React, { useEffect, useState } from "react"
import { Dialog, Icon } from "@blueprintjs/core"
import * as queries from "../../graphql/queries"
import * as mutations from "../../graphql/mutations"
import gql from "graphql-tag"
import { userPoolsClient } from "../../../gatsby-browser"
import AppToaster from "../../utils/appToaster"
import FAQSection from "../FAQSection"

const FAQSectionPanel = () => {
  const [sections, setSections] = useState([])
  const [sectionName, setSectionName] = useState("")
  const [sectionPoints, setSectionPoints] = useState([""])
  const [open, setOpen] = useState(false)
  const [selectedId, setSelectedId] = useState("")

  const fetchFAQSectons = async () => {
    const secs = await userPoolsClient.query({
      query: gql(queries.listFaqSections),
      fetchPolicy: "network-only",
      variables: {
        limit: 100000,
      },
    })
    if (secs.data.listFAQSections) {
      setSections(secs.data.listFAQSections.items)
    }
  }

  useEffect(() => {
    fetchFAQSectons()
  }, [])

  const handleNewSection = async e => {
    e.preventDefault()

    try {
      await userPoolsClient.mutate({
        mutation: gql(mutations.createFaqSection),
        variables: {
          input: {
            id: sectionName,
            open: false,
            text: sectionPoints,
          },
        },
      })

      AppToaster.show({
        intent: "success",
        message: `New FAQ Section Created Successfully`,
        icon: "thumbs-up",
      })

      setSectionName("")
      setSectionPoints([""])
      fetchFAQSectons()
    } catch (err) {
      AppToaster.show({
        intent: "danger",
        message: `${err.message}`,
        icon: "error",
      })
    }
  }

  const newSection = (
    <form className="mt-8" onSubmit={handleNewSection}>
      <hr />
      <h3 className="text-gray-800 font-bold text-xl mt-8">New Section</h3>
      <div className="my-4 ">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="Section Name"
        >
          Section Name
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="Section Name"
          type="text"
          value={sectionName}
          onChange={e => setSectionName(e.target.value)}
        />
      </div>
      <h4 className="text-gray-800 font-semibold">Bullet points</h4>
      {sectionPoints.map((point, index) => {
        return (
          <div
            className="flex justify-start items-center"
            key={`bullet-point-${index}`}
          >
            <p className="w-10">{index + 1}: </p>
            <div className="my-4 ">
              <input
                className="shadow appearance-none border rounded w-196 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id={`section-point-${index}`}
                type="text"
                value={point}
                onChange={e => {
                  let current = [...sectionPoints]
                  current[index] = e.target.value
                  setSectionPoints(current)
                }}
              />
            </div>
            {index === sectionPoints.length - 1 ? (
              <button
                type="button"
                onClick={() => setSectionPoints([...sectionPoints, ""])}
                className="default-btn w-10 ml-4"
              >
                +
              </button>
            ) : (
              <button
                type="button"
                onClick={() => {
                  setSectionPoints(
                    [...sectionPoints].filter((v, i) => i !== index)
                  )
                }}
                className="default-btn bg-red-500 hover:bg-red-600 w-10 ml-4"
              >
                -
              </button>
            )}
          </div>
        )
      })}
      <button type="submit" className="default-btn w-40 mb-8">
        Add Section{" "}
      </button>
      <hr />
    </form>
  )

  const handleSectionDelete = async id => {
    try {
      await userPoolsClient.mutate({
        mutation: gql(mutations.deleteFaqSection),
        variables: {
          input: {
            id,
          },
        },
      })

      AppToaster.show({
        intent: "success",
        message: `FAQ Section Deleted Successfully`,
        icon: "thumbs-up",
      })

      fetchFAQSectons()
      setOpen(false)
    } catch (err) {
      AppToaster.show({
        intent: "danger",
        message: `${err.message}`,
        icon: "error",
      })
    }
  }

  const dialog = (
    <Dialog isOpen={open} onClose={() => setOpen(false)}>
      <div className="bp3-dialog-header">
        <Icon icon="trash" />
        <h4 className="bp3-heading">Confirm Deletion</h4>
        <button
          className="second-btn w-8 my-2 py-1"
          onClick={() => setOpen(false)}
        >
          X
        </button>
      </div>
      <div className="bp3-dialog-body">
        Are you sure you want to delete section {selectedId}?
      </div>
      <div className="bp3-dialog-footer">
        <div className="bp3-dialog-footer-actions">
          <button
            className="danger-btn w-32 mr-2"
            onClick={() => handleSectionDelete(selectedId)}
          >
            Yes
          </button>
          <button className="second-btn w-32" onClick={() => setOpen(false)}>
            No
          </button>
        </div>
      </div>
    </Dialog>
  )

  return (
    <>
      {dialog}
      <div>
        <button onClick={fetchFAQSectons} className="default-btn w-32 right-0">
          Refresh
        </button>
        {newSection}
        <h3 className="font-bold text-gray-800 mt-10 text-xl">Preview</h3>
        <FAQSection
          sections={sections}
          onClickTab={id => {
            setSelectedId(id)
            setOpen(true)
          }}
          functionLabel="Delete"
        />
      </div>
    </>
  )
}

export default FAQSectionPanel
