import * as mutations from "../../graphql/mutations"
import gql from "graphql-tag"
import { userPoolsClient } from "../../../gatsby-browser"
import AppToaster from "../appToaster"

export const uploadPlateData = async plate => {
  const { id, name, date, results } = plate
  await userPoolsClient.mutate({
    mutation: gql(mutations.createPlate),
    variables: {
      input: {
        id,
        name,
        date,
      },
    },
  })

  let count = 0
  let arr = []
  //keep batch sizes below 25 - maximum batch size for ddb
  for (let result of results) {
    arr.push(result)
    count++
    if (count === 15) {
      await userPoolsClient.mutate({
        mutation: gql(mutations.batchAddResults),
        variables: {
          results: arr,
        },
      })
      arr = []
      count = 0
    }
  }

  if (arr.length > 0) {
    await userPoolsClient.mutate({
      mutation: gql(mutations.batchAddResults),
      variables: {
        results: arr,
      },
    })
  }
}

export const uploadPools = async pools => {
  for (let pool of pools) {
    try {
      const { id, date, name, resultIds } = pool
      await userPoolsClient.mutate({
        mutation: gql(mutations.createPoolList),
        variables: {
          input: {
            id,
            date,
            name,
            resultIds,
          },
        },
      })
    } catch (e) {
      AppToaster.show({
        intent: "danger",
        message: `Something went wrong. You may have already uploaded these pool ids for this date.`,
        icon: "error",
      })
    }
  }
}
